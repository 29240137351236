import React from 'react';

const Dashboard = () => {
  const robotName = localStorage.getItem('robotName');
  const missionName = localStorage.getItem('missionName');

  
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="text-center text-white">
        <h1 className="text-3xl mb-4">Welcome to Your Dashboard</h1>
        <p className="text-lg">Robot Name: {robotName}</p>
        <p className="text-lg">Mission Name: {missionName}</p>
      </div>
    </div>
  );
};

export default Dashboard;
