import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const MissionUpload = () => {
  const location = useLocation();  
  const { robotName, missionName, dataType } = location.state || {};
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [message, setMessage] = useState('');
  const [currentTab, setCurrentTab] = useState('new');
  const [uploadedCount, setUploadedCount] = useState(0);
  const totalFilesNeeded = 15; 
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [readyForCalibration, setReadyForCalibration] = useState(false); 

  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);
    if (tab === 'recent') {
      fetchRecentFiles();  
    }
  };

  const fetchRecentFiles = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://ridescanbackend.azurewebsites.net/api/recent-files', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setUploadedFiles(response.data);
    } catch (error) {
      console.error('Error fetching recent files:', error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
  
    const newFiles = selectedFiles.filter((file) => !files.find((f) => f.name === file.name));
  
    if (newFiles.length === 0) {
      setMessage('No new files selected or files already added.');
      return;
    }
  
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setTimeout(() => {
      e.target.value = '';
    }, 500);
    setMessage('');
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setMessage('Please select at least one file.');
      return;
    }

    const token = localStorage.getItem('token');
    let newUploadCount = uploadedCount;

    files.forEach(async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('robot-name', robotName);  
      formData.append('mission-name', missionName);  
      formData.append('data-type', dataType);  

      try {
        await axios.post('https://ridescanbackend.azurewebsites.net/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progress,
            }));
          },
        });

        setMessage('Upload successful!');
        newUploadCount += 1;
        setUploadedCount(newUploadCount);
        setUploadedFiles((prevUploaded) => [...prevUploaded, file.name]);
        setFiles([]);  
        fileInputRef.current.value = '';  

        if (newUploadCount >= totalFilesNeeded) {
          setReadyForCalibration(true);
        }

      } catch (error) {
        console.error('Error uploading file:', error);
        setMessage('Failed to upload some files.');
      }
    });
  };

  const handleCalibration = () => {
    navigate('/calibration-progress', {
      state: { robotName, missionName }  
    });
  };

  return (
    <div className="min-h-screen flex bg-white">
      <div className="w-3/4 p-16">
        <h1 className="text-5xl font-semibold mb-4 text-gray-800">Mission Data</h1>
        <p className="text-lg text-gray-500 mb-6">
          Upload your mission data for robot <strong>{robotName}</strong> and mission <strong>{missionName}</strong>. Minimum of 15 data files required.
        </p>

        {readyForCalibration && (
          <div className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
            <p className="font-bold">RideScan is ready for calibration!</p>
            <p>You have uploaded {uploadedCount} files. Click the button below to proceed to calibration.</p>
            <button
              className="mt-4 bg-green-600 px-5 py-3 rounded-md text-white hover:bg-green-700 transition duration-300"
              onClick={handleCalibration}
            >
              Proceed to Calibration
            </button>
          </div>
        )}

        <div className="flex mb-4">
          <button
            className={`px-4 py-2 ${currentTab === 'new' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'}`}
            onClick={() => handleTabSwitch('new')}
          >
            New upload
          </button>
          <button
            className={`px-4 py-2 ml-2 ${currentTab === 'recent' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'}`}
            onClick={() => handleTabSwitch('recent')}
          >
            Recent
          </button>
        </div>

        {currentTab === 'new' && (
          <div
            className="border-dashed border-4 border-gray-300 rounded-lg p-12 flex items-center justify-center relative cursor-pointer"
            onClick={() => fileInputRef.current.click()}
          >
            <input
              type="file"
              id="file-input"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="opacity-0 absolute inset-0 z-50 cursor-pointer"
              multiple
            />
            {files.length > 0 ? (
              <div className="text-center text-gray-700">
                <p>{files.length} new files selected</p>
                <p className="text-sm text-gray-500">Click to choose more files or drag them here</p>
              </div>
            ) : (
              <div className="text-center text-gray-400">
                <p>Click to browse or drag and drop your files</p>
              </div>
            )}
          </div>
        )}

        {currentTab === 'recent' && (
          <div className="space-y-4">
            {uploadedFiles.map((file) => (
              <div key={file.id} className="flex items-center justify-between border-b pb-4">
                <div className="flex items-center space-x-4">
                  <span className="text-lg font-semibold">{file.name}</span>
                  <span className="text-gray-400 text-sm">{file.size} KB</span>
                </div>
                <div className="flex items-center space-x-4">
                  {file.status === 'error' ? (
                    <span className="text-red-500">Error</span>
                  ) : (
                    <span className="text-gray-400">{file.date}</span>
                  )}
                  <button className="text-red-600 hover:text-red-800">
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {currentTab === 'new' && files.length > 0 && (
          <button
            className="mt-6 w-1/2 bg-blue-600 px-5 py-3 rounded-md text-white hover:bg-blue-700 transition duration-300 font-medium"
            onClick={handleUpload}
          >
            Upload {files.length} New Files
          </button>
        )}

        {Object.keys(uploadProgress).map((fileName) => (
          <div key={fileName} className="mt-4">
            <p>{fileName}</p>
            <div className="bg-gray-200 rounded-full h-2.5">
              <div
                style={{ width: `${uploadProgress[fileName]}%` }}
                className="bg-blue-600 h-full rounded-full"
              ></div>
            </div>
          </div>
        ))}

        {message && <p className="mt-4 text-blue-600">{message}</p>}

      </div>

      <div className="w-1/4 bg-gray-900 text-white p-8">
        <div className="sticky top-8">
          <h2 className="text-lg font-semibold mb-4">Quick & Easy Setup</h2>
          <ul className="space-y-4">
            <li className="text-blue-400 font-semibold">
              Basic information (3 out of 3)
            </li>
            <li className="text-blue-400 font-semibold">
              Upload mission data ({uploadedCount} out of {totalFilesNeeded})
            </li>
            <li className="text-gray-400">Data quality assessment</li>
            <li className="text-gray-400">Calibration</li>
            <li className="text-gray-400">Completion</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MissionUpload;


