import React from 'react';
import { useNavigate } from 'react-router-dom';

const Setup = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/setup/robot');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 relative">
      {/* Background Animation */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="animated-dots bg-opacity-20 h-full w-full absolute"></div>
      </div>

      {/* Content */}
      <div className="relative z-10 text-center text-white">
        <h1 className="text-3xl mb-4">Welcome to RideScan</h1>
        <p className="mb-6">Your all-in-one robotics data platform. Let’s get started!</p>
        <button className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-700 transition duration-300" onClick={handleStart}>
          Let's get started
        </button>
      </div>
    </div>
  );
};

export default Setup;



