import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, CircularProgress, Typography, Snackbar } from '@mui/material';
import RobotOverview from './RobotOverview';
import MissionOverview from './MissionOverview';
import MissionDetails from './MissionDetails';
import { useNavigate } from 'react-router-dom';

const ManufacturerDashboard = () => {
  const [robots, setRobots] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRobot, setSelectedRobot] = useState(null);
  const [selectedMission, setSelectedMission] = useState(null);
  const [uploading, setUploading] = useState(false); // Track file upload status
  const [calculatingRisk, setCalculatingRisk] = useState(null); // Track risk calculation
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
  const navigate = useNavigate();

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchRobots = async () => {
      try {
        const token = localStorage.getItem('token');
        //const response = await axios.get('http://127.0.0.1:8000/api/files',
        const response = await axios.get('https://ridescanbackend.azurewebsites.net/api/files', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setRobots(response.data.files_by_robot);
        } else {
          setError('Failed to load robots');
        }
      } catch (error) {
        setError('Error loading robots: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRobots();
  }, []);

  // Function to handle file upload
  const handleFileUpload = async (file) => {
    if (!file) {
      alert('Please select a file to upload');
      return;
    }

    setUploading(true); // Set the uploading state to true
    const formData = new FormData();
    formData.append('file', file);
    formData.append('robot-name', selectedRobot);
    formData.append('mission-name', selectedMission);
    formData.append('data-type', 'routine');

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://ridescanbackend.azurewebsites.net/api/upload', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200 && response.data) {
        const newFile = response.data.newFile;
        // Update robots state with the new file
        setRobots((prev) => ({
          ...prev,
          [selectedRobot]: {
            ...prev[selectedRobot],
            [selectedMission]: [...prev[selectedRobot][selectedMission], {
              id: newFile.id,
              original_filename: newFile.original_filename,
              risk_score: null,
              upload_time: newFile.upload_time,
            }],
          },
        }));
        setSnackbarMessage('File uploaded successfully!');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error uploading file');
      setSnackbarOpen(true);
      console.error('Error uploading file:', error);
    } finally {
      setUploading(false); // Reset the uploading state
    }
  };

  // Function to handle risk score calculation
  const handleCalculateRisk = async (fileId) => {
    try {
      setCalculatingRisk(fileId); // Set the current file being processed
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://ridescanbackend.azurewebsites.net/analyse_risk/${fileId}`, null, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data) {
        // Update state with new risk score
        setRobots((prevRobots) => ({
          ...prevRobots,
          [selectedRobot]: {
            ...prevRobots[selectedRobot],
            [selectedMission]: prevRobots[selectedRobot][selectedMission].map((file) =>
              file.id === fileId
                ? { ...file, risk_score: response.data.percent }
                : file
            ),
          },
        }));
        setSnackbarMessage(`Risk Score: ${response.data.percent}%`);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Failed to calculate risk score');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error calculating risk score');
      setSnackbarOpen(true);
      console.error('Error calculating risk score:', error);
    } finally {
      setCalculatingRisk(null); // Reset risk calculation state
    }
  };

  // Function to handle adding a new robot
  const handleAddRobot = () => {
    navigate('/setup'); // Navigate to the setup page for adding a new robot
  };

  // Function to handle adding a new mission
  const handleAddMission = () => {
    navigate(`/setup?robot=${selectedRobot}`); // Pass the selected robot in the URL query
  };

  if (loading) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px' }}>
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: '20px' }}>Loading robots...</Typography>
      </Container>
    );
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  if (!selectedRobot) {
    return (
      <Container maxWidth="lg" style={{ marginTop: '50px' }}>
        <RobotOverview 
          robots={robots} 
          onRobotSelect={setSelectedRobot} 
          onAddRobot={handleAddRobot} // Handle adding a new robot
        />
      </Container>
    );
  }

  if (!selectedMission) {
    return (
      <Container maxWidth="lg" style={{ marginTop: '50px' }}>
        <MissionOverview
          missions={Object.keys(robots[selectedRobot])}
          onMissionSelect={setSelectedMission}
          onBack={() => setSelectedRobot(null)}
          onAddMission={handleAddMission} // Handle adding a new mission
          robotName={selectedRobot}
        />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: '50px' }}>
      <MissionDetails
        mission={selectedMission}
        files={robots[selectedRobot][selectedMission]}
        onBack={() => setSelectedMission(null)}
        onFileUpload={handleFileUpload}
        uploading={uploading}
        onCalculateRisk={handleCalculateRisk}
        calculatingRisk={calculatingRisk}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default ManufacturerDashboard;


