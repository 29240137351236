import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, IconButton, Switch, FormControlLabel, Fab } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RiskChart from './RiskChart';
import FileUpload from './FileUpload';
import PrintIcon from '@mui/icons-material/Print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ridescanLogo from '../assets/ridescanLogo.png'; // Ensure the logo is in the assets folder

// Helper function to calculate average risk score for the mission
const calculateAverageRiskScore = (files) => {
  let totalScore = 0;
  let count = 0;

  files.forEach(file => {
    if (file.risk_score !== null && !isNaN(file.risk_score)) { // Ensure valid risk score
      totalScore += file.risk_score;
      count++;
    }
  });

  return count > 0 ? (totalScore / count).toFixed(2) : 'N/A'; // 'N/A' if no files have valid risk scores
};

// Helper function to determine health condition based on average risk score
const determineHealthCondition = (averageRisk) => {
  if (averageRisk === 'N/A') return 'No Data';
  if (averageRisk < 25) return 'Good';
  if (averageRisk < 50) return 'Moderate';
  return 'Critical';
};

const MissionDetails = ({ mission, files, onFileUpload, uploading, onCalculateRisk, onBack }) => {
  const [processingFiles, setProcessingFiles] = useState([]); // Track files being processed
  const [updatedFiles, setUpdatedFiles] = useState(files); // State to store updated files
  const [isProcessing, setIsProcessing] = useState(false); // To track if a calculation is ongoing
  const [isAutomated, setIsAutomated] = useState(false); // Toggle state for manual/automated upload
  const [requestSubmitted, setRequestSubmitted] = useState(false); // Track request submission
  const contentRef = useRef(); // Ref to capture content for PDF

  const averageRisk = calculateAverageRiskScore(updatedFiles);
  const healthCondition = determineHealthCondition(averageRisk);

  // Memoize the handleCalculateRisk function to prevent unnecessary re-renders
  const handleCalculateRisk = useCallback(async (fileId) => {
    setProcessingFiles((prev) => [...prev, fileId]); // Add to processing
    setIsProcessing(true); // Indicate that processing has started

    // Await the calculation from the parent callback
    const riskScore = await onCalculateRisk(fileId);

    // Update the files with the new risk score
    setUpdatedFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId ? { ...file, risk_score: riskScore } : file
      )
    );

    setProcessingFiles((prev) => prev.filter(id => id !== fileId)); // Remove from processing list
    setIsProcessing(false); // Indicate that processing has finished
  }, [onCalculateRisk]);

  // Sequentially process files that don't have a risk score
  useEffect(() => {
    const filesNeedingRiskScore = updatedFiles.filter(file => file.risk_score === null && !processingFiles.includes(file.id));

    // If there's no calculation ongoing and files need processing, start processing the first file in the queue
    if (!isProcessing && filesNeedingRiskScore.length > 0) {
      handleCalculateRisk(filesNeedingRiskScore[0].id);
    }
  }, [updatedFiles, isProcessing, handleCalculateRisk, processingFiles]); // Add all dependencies

  const handleToggleChange = (event) => {
    if (!requestSubmitted) {
      setIsAutomated(event.target.checked); // Toggle between manual and automated
    }
  };

  const handleRequestSubmit = () => {
    // Placeholder for future API call for automated data upload request
    setRequestSubmitted(true);
    alert('Request submitted! Our customer care will assist you soon.');
  };

  const handleGenerateReport = async () => {
    const content = contentRef.current;

    // Convert content to canvas using html2canvas
    const canvas = await html2canvas(content, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');

    // Create a new PDF document
    const pdf = new jsPDF('p', 'mm', 'a4');
    
    // Add RideScan logo at the top of the PDF
    const imgLogo = new Image();
    imgLogo.src = ridescanLogo;
    pdf.addImage(imgLogo, 'PNG', 10, 10, 30, 15); // Adjust position and size as needed

    // Add high-level information
    pdf.text(`Mission Report: ${mission}`, 10, 40); // Mission title below the logo
    pdf.text(`Average Risk Score: ${averageRisk}%`, 10, 50);
    pdf.text(`Health Condition: ${healthCondition}`, 10, 60);

    // Add the chart image to the PDF
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 10, 70, pdfWidth - 20, pdfHeight - 30); // Position and size chart

    // Add footer indicating the report was generated by RideScan
    pdf.text('This report was generated by RideScan AI', 10, pdf.internal.pageSize.getHeight() - 10);

    // Save the PDF
    pdf.save(`${mission}-report.pdf`);
  };

  // Prepare data for the chart with 'Day X' labels
  const chartData = updatedFiles.map((file, index) => ({
    ...file,
    dayLabel: `Day ${index + 1}` // X-axis label as 'Day X'
  }));

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: '30px', fontWeight: 'bold' }}>Mission: {mission}</Typography>
      <Button variant="outlined" color="secondary" onClick={onBack} style={{ marginBottom: '20px' }}>
        Back to All Missions
      </Button>

      {/* Overall Mission Health */}
      <Card style={{ marginBottom: '20px' }}>
        <CardContent>
          <Typography variant="h6" style={{ marginBottom: '10px' }}>Overall Mission Health</Typography>
          <Typography>Average Risk Score: {averageRisk !== 'NaN' ? `${averageRisk}%` : 'Calculating...'}</Typography>
          <Typography 
            style={{ fontWeight: 'bold', color: healthCondition === 'Critical' ? 'red' : healthCondition === 'Moderate' ? 'orange' : 'green' }}
          >
            Health: {healthCondition}
          </Typography>
        </CardContent>
      </Card>

      {/* Upload Data Card */}
      <Card style={{ marginBottom: '20px' }}>
        <CardContent>
          {/* Upload Section Title with Toggle */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" style={{ marginBottom: '20px' }}>Upload Data for Mission</Typography>
            <FormControlLabel
              control={<Switch checked={isAutomated} onChange={handleToggleChange} disabled={requestSubmitted} />} // Disable toggle if request is submitted
              label={isAutomated ? 'Automated' : 'Manual'}
            />
          </div>

          {/* Show different content based on toggle state */}
          {isAutomated ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              {/* Request section for automated upload */}
              {requestSubmitted ? (
                <Typography>Your request for automated upload has been submitted. Our team will contact you shortly.</Typography>
              ) : (
                <>
                  <Typography>
                    Automated data upload is a premium service. Submit a request to our customer care team to enable this feature for your robot.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '20px' }}
                    onClick={handleRequestSubmit}
                  >
                    Submit Request
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div>
              {/* Manual upload fields */}
              <Typography>Manually upload your mission data:</Typography>
              <FileUpload onUpload={onFileUpload} uploading={uploading} /> {/* File Upload UI */}
            </div>
          )}
        </CardContent>
      </Card>

      {/* Risk Chart Card */}
      <Card style={{ marginBottom: '20px' }}>
        <CardContent ref={contentRef}>
          <Typography variant="h6" style={{ marginBottom: '20px' }}>Risk Score Chart</Typography>
          {/* Update the chart with 'Day X' labels */}
          <RiskChart data={chartData} />

          {/* Table with File Information */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Mission Instance</TableCell>
                <TableCell>Risk Score</TableCell>
                <TableCell>Date Uploaded</TableCell>
                <TableCell>Actions</TableCell> {/* Keep actions here */}
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedFiles.map((file, index) => (
                <TableRow key={file.id}>
                  <TableCell>{`Mission Instance Day ${index + 1}`}</TableCell> {/* Dynamic Mission Instance */}
                  <TableCell>
                    {typeof file.risk_score === 'number'
                      ? <Typography style={{ color: '#4caf50' }}>{file.risk_score.toFixed(2)}</Typography>
                      : <Button disabled variant="contained" color="primary">Processing...</Button>}
                  </TableCell>
                  <TableCell>{new Date(file.upload_time).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <IconButton color="primary" aria-label="view file">
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton color="secondary" aria-label="delete file">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {/* Floating Action Button for Generating Report */}
      <Fab
        color="primary"
        aria-label="generate report"
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
        onClick={handleGenerateReport} // Generate PDF when clicked
      >
        <PrintIcon />
      </Fab>
    </>
  );
};

export default MissionDetails;













