import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Typography, Box } from '@mui/material';

const FileUpload = ({ onUpload, uploading }) => {
  const [file, setFile] = useState(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.bag',
    onDrop: acceptedFiles => {
      setFile(acceptedFiles[0]);
    }
  });

  const handleUpload = () => {
    if (file) {
      onUpload(file);
    }
  };

  return (
    <Box 
      {...getRootProps()} 
      sx={{
        border: '2px dashed #ddd',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: isDragActive ? '#f0f0f0' : 'white',
        borderRadius: '8px',
        marginBottom: '20px'
      }}
    >
      <input {...getInputProps()} />
      <Typography variant="body1">
        {file ? file.name : 'Drag and drop a file here, or click to select a file'}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={uploading || !file}
        style={{ marginTop: '10px' }}
      >
        {uploading ? 'Uploading...' : 'Upload File'}
      </Button>
    </Box>
  );
};

export default FileUpload;

