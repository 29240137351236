import React from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';

const MissionOverview = ({ missions, onMissionSelect, onBack, robotName }) => {
  const navigate = useNavigate();

  // Debugging: Log robotName to ensure it’s passed correctly
  console.log('Robot Name being passed to MissionOverview:', robotName);

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: '30px', fontWeight: 'bold' }}>Missions</Typography>
      <Button variant="outlined" color="secondary" onClick={onBack} style={{ marginBottom: '20px' }}>
        Back to All Robots
      </Button>

      <Grid container spacing={3}>
        {missions.map((missionName) => (
          <Grid item xs={12} sm={6} md={4} key={missionName}>
            <Card
              style={{ height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
              onClick={() => onMissionSelect(missionName)}
            >
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>{missionName}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {/* Plus sign for adding a new mission */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            style={{ height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
            onClick={() => {
              console.log('Navigating to Robot Setup with robotName:', robotName);
              navigate('/setup/robot', { state: { robotName } });
            }}
          >
            <CardContent style={{ textAlign: 'center' }}>
              <AddCircleOutlineIcon style={{ fontSize: '50px', color: 'gray' }} />
              <Typography variant="h6" style={{ marginTop: '10px', color: 'gray' }}>Add New Mission</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default MissionOverview;





