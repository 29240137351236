import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Setup from './components/Setup';
import RobotSetup from './components/RobotSetup';
import Dashboard from './components/Dashboard';
import MissionUpload from './components/MissionUpload';
import CalibrationProgress from './components/CalibrationProgress';
import ManufacturerDashboard from './components/ManufacturerDashboard';
import MissionOverview from './components/MissionOverview';
import MissionDetails from './components/MissionDetails';
import Layout from './components/Layout';  // Import the Layout

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public routes without Navbar */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Protected routes with Navbar (wrapped in Layout) */}
        <Route 
          path="/setup" 
          element={
            <Layout>
              <Setup />
            </Layout>
          }
        />
        <Route 
          path="/setup/robot" 
          element={
            <Layout>
              <RobotSetup />
            </Layout>
          }
        />
        <Route 
          path="/setup/mission-upload" 
          element={
            <Layout>
              <MissionUpload />
            </Layout>
          }
        />
        <Route 
          path="/dashboard" 
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route 
          path="/calibration-progress" 
          element={
            <Layout>
              <CalibrationProgress />
            </Layout>
          }
        />
        <Route 
          path="/manufacturer-dashboard" 
          element={
            <Layout>
              <ManufacturerDashboard />
            </Layout>
          }
        />
        <Route 
          path="/missions" 
          element={
            <Layout>
              <MissionOverview />
            </Layout>
          }
        />
        <Route 
          path="/missions/:missionName" 
          element={
            <Layout>
              <MissionDetails />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;



