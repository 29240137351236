import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';  // import useNavigate and Link

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // To navigate between pages

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post('https://ridescanbackend.azurewebsites.net/api/login', {
        email,
        password
      });

      // Save the token in localStorage
      localStorage.setItem('token', response.data.access_token);

      // Call API to check if user has set up a robot
      const setupResponse = await axios.get('https://ridescanbackend.azurewebsites.net/api/check-setup', {
        headers: {
          'Authorization': `Bearer ${response.data.access_token}`
        }
      });

      // Check the robot setup status
      if (setupResponse.data.has_setup_robot) {
        // If the user has already set up a robot, navigate to the dashboard
        navigate('/manufacturer-dashboard');
      } else {
        // If it's the first time, navigate to the setup page
        navigate('/setup');
      }
      
    } catch (err) {
      setError('Invalid credentials');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 relative">
      {/* Background Animation */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="animated-dots bg-opacity-20 h-full w-full absolute"></div>
      </div>

      {/* Form Container */}
      <div className="relative z-10 w-full max-w-md bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">RideScan</h2>
        <h3 className="text-md text-gray-600 mb-4 text-center">Welcome back! Log in to access your dashboard.</h3>

        {/* Show Error Message */}
        {error && <div className="text-red-600 text-center mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          {/* Email Input */}
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1 focus:ring focus:ring-blue-200"
              placeholder="Enter your email"
              required
            />
          </div>

          {/* Password Input */}
          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1 focus:ring focus:ring-blue-200"
              placeholder="Enter your password"
              required
            />
          </div>

          {/* Sign In Button */}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Sign In'}
          </button>
        </form>

        {/* Footer */}
        <div className="text-center mt-4 text-gray-500 text-sm">
          No account? 
          <Link to="/register" className="text-blue-600 hover:underline">
            Create one
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;



