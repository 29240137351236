import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const CalibrationProgress = () => {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();  // Use useLocation to access the state
  const { robotName, missionName } = location.state || {};  // Destructure the state passed from the previous page

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 10;  // Simulating the progress increase
        } else {
          clearInterval(interval);

          // Update the user setup status in the backend
          const updateSetupStatus = async () => {
            const token = localStorage.getItem('token');
            try {
              await axios.post('https://ridescanbackend.azurewebsites.net/api/setup-robot', {}, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              });
            } catch (err) {
              console.error("Failed to update setup status", err);
            }

            // Navigate to the dashboard after updating the status
            setTimeout(() => {
              navigate('/manufacturer-dashboard');  // Redirect to ManufacturerDashboard
            }, 2000);  // Small delay after completion
          };

          updateSetupStatus();
          return prevProgress;
        }
      });
    }, 1000); // Progress increments every second

    return () => clearInterval(interval);  // Cleanup interval on component unmount
  }, [navigate]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Please wait while RideScan is learning from your data</h1>
      <p className="text-lg text-gray-600 mb-8">
        We are calibrating RideScan for your <span className="font-semibold">{robotName}</span> doing 
        <span className="font-semibold"> {missionName}</span>.
      </p>
      
      {/* Progress Bar */}
      <div className="w-3/4 bg-gray-200 rounded-full h-4 mb-8">
        <div
          className="bg-blue-600 h-full rounded-full transition-all duration-300"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      
      {progress === 100 && (
        <p className="text-green-600 text-xl font-medium">Calibration Complete! Redirecting to the dashboard...</p>
      )}
    </div>
  );
};

export default CalibrationProgress;
