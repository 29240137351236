import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RobotSetup = () => {
  const navigate = useNavigate();
  const location = useLocation();  // Access the location object
  const passedRobotName = location.state?.robotName || '';  // Check if robotName was passed in state

  // Set robotName to the passed value, or default to empty if not provided
  const [robotName, setRobotName] = useState(passedRobotName);
  const [step, setStep] = useState(1);
  const [missionName, setMissionName] = useState('');
  const [dataType, setDataType] = useState('');

  // Debugging: Check if robotName is correctly received
  useEffect(() => {
    if (passedRobotName) {
      console.log("Robot name passed to setup:", passedRobotName);
    } else {
      console.log("No robot name passed, user will input manually.");
    }
  }, [passedRobotName]);

  const handleNext = () => {
    if (step < 3) setStep(step + 1);
  };

  const handleSubmit = () => {
    if (!robotName || !missionName || !dataType) {
      alert("Please complete all steps");
      return;
    }
    navigate('/setup/mission-upload', {
      state: { robotName, missionName, dataType }
    });
  };

  return (
    <div className="min-h-screen flex bg-white">
      <div className="w-3/4 p-16">
        <h1 className="text-5xl font-semibold mb-4 text-gray-800">Basic Information</h1>
        <p className="text-lg text-gray-500 mb-6">Add the basic information to your asset</p>

        <div className="mb-8">
          <div className="font-medium text-blue-500 text-xl">
            {step === 1 && "Step 1 of 3: Add Robot Name"}
            {step === 2 && "Step 2 of 3: Add Mission Name"}
            {step === 3 && "Step 3 of 3: Choose Data Type"}
          </div>
        </div>

        {/* Step 1: Add Robot Name */}
        <div className={`mb-6 ${step >= 1 ? '' : 'hidden'}`}>
          <label htmlFor="robot-name" className="block text-sm font-medium text-gray-600 mb-2">
            Add Robot Name
          </label>
          <input
            type="text"
            id="robot-name"
            value={robotName}  // Pre-fill the robot name from the passed state
            onChange={(e) => setRobotName(e.target.value)}
            placeholder="Start typing the name ..."
            className={`w-full p-3 border ${step === 1 ? 'border-gray-300' : 'border-gray-200'} rounded-md focus:ring focus:ring-blue-300 transition-shadow`}
            disabled={step > 1}  // Disable if step > 1
            required
          />
          {step === 1 && (
            <button
              className="mt-6 w-1/2 bg-blue-600 px-5 py-3 rounded-md text-white hover:bg-blue-700 transition duration-300 font-medium"
              onClick={handleNext}
            >
              Save & go next
            </button>
          )}
        </div>

        {/* Step 2: Add Mission Name */}
        <div className={`mb-6 ${step >= 2 ? '' : 'opacity-30'}`}>
          <label htmlFor="mission-name" className="block text-sm font-medium text-gray-600 mb-2">
            Add Mission Name
          </label>
          <input
            type="text"
            id="mission-name"
            value={missionName}
            onChange={(e) => setMissionName(e.target.value)}
            placeholder="Start typing the mission name ..."
            className={`w-full p-3 border ${step === 2 ? 'border-gray-300' : 'border-gray-200'} rounded-md focus:ring focus:ring-blue-300 transition-shadow`}
            disabled={step < 2}
            required
          />
          {step === 2 && (
            <button
              className="mt-6 w-1/2 bg-blue-600 px-5 py-3 rounded-md text-white hover:bg-blue-700 transition duration-300 font-medium"
              onClick={handleNext}
            >
              Save & go next
            </button>
          )}
        </div>

        {/* Step 3: Choose Data Type */}
        <div className={`mb-6 ${step >= 3 ? '' : 'opacity-30'}`}>
          <label htmlFor="data-type" className="block text-sm font-medium text-gray-600 mb-2">
            Choose Data Type
          </label>
          <select
            id="data-type"
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
            className={`w-full p-3 border ${step === 3 ? 'border-gray-300' : 'border-gray-200'} rounded-md focus:ring focus:ring-blue-300 transition-shadow`}
            disabled={step < 3}
            required
          >
            <option value="" disabled>Select a data type</option>
            <option value="Routine">Routine</option>
            <option value="Anomaly">Anomaly</option>
          </select>
          {step === 3 && (
            <button
              className="mt-6 w-1/2 bg-blue-600 px-5 py-3 rounded-md text-white hover:bg-blue-700 transition duration-300 font-medium"
              onClick={handleSubmit}
            >
              Save & Complete Setup
            </button>
          )}
        </div>
      </div>

      <div className="w-1/4 bg-gray-900 text-white p-8">
        <div className="sticky top-8">
          <h2 className="text-lg font-semibold mb-4">Quick & Easy Setup</h2>
          <ul className="space-y-4">
            <li className={step >= 1 ? 'text-blue-400 font-semibold' : 'text-gray-400'}>
              Basic information ({step} out of 3)
            </li>
            <li className="text-gray-400">Upload mission data</li>
            <li className="text-gray-400">Data quality assessment</li>
            <li className="text-gray-400">Calibration</li>
            <li className="text-gray-400">Completion</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RobotSetup;








