import React from 'react';
import { Grid, Card, CardContent, Typography, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';

const RobotOverview = ({ robots, onRobotSelect }) => {
  const navigate = useNavigate();

  // Helper function to calculate average risk score
  const calculateAverageRiskScore = (missions) => {
    let totalScore = 0;
    let fileCount = 0;
    
    Object.keys(missions).forEach((mission) => {
      missions[mission].forEach((file) => {
        if (file.risk_score !== null) {
          totalScore += file.risk_score;
          fileCount++;
        }
      });
    });

    return fileCount > 0 ? (totalScore / fileCount).toFixed(2) : 'N/A';
  };

  // Helper function to determine health condition
  const determineHealthCondition = (averageRisk) => {
    if (averageRisk === 'N/A') return 'No Data';
    if (averageRisk < 25) return 'Good';
    if (averageRisk < 50) return 'Moderate';
    return 'Critical';
  };

  // Function to handle adding a new robot
  const handleAddRobot = () => {
    navigate('/setup/robot'); // Directly navigate to the robot name setup page
  };

  return (
    <>
      {/* Overall page heading */}
      <Typography variant="h4" style={{ marginBottom: '30px', fontWeight: 'bold' }}>Manufacturer Dashboard</Typography>
      
      <Grid container spacing={3}>
        {Object.keys(robots).map((robotName) => {
          const averageRisk = calculateAverageRiskScore(robots[robotName]);
          const healthCondition = determineHealthCondition(averageRisk);
          
          return (
            <Grid item xs={12} sm={6} md={4} key={robotName}>
              <Card style={{ height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <CardContent style={{ textAlign: 'center' }}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>{robotName}</Typography>
                  <Typography variant="body2" style={{ marginTop: '10px' }}>
                    Average Risk Score: {averageRisk}
                  </Typography>
                  <Typography variant="body2" style={{ marginTop: '10px', fontWeight: 'bold', color: healthCondition === 'Critical' ? 'red' : healthCondition === 'Moderate' ? 'orange' : 'green' }}>
                    Health Condition: {healthCondition}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onRobotSelect(robotName)}
                    style={{ marginTop: '20px' }}
                  >
                    View Details
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        })}

        {/* Card for adding a new robot */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
            onClick={handleAddRobot}
          >
            <CardContent style={{ textAlign: 'center' }}>
              <AddCircleOutlineIcon style={{ fontSize: '50px', color: 'gray' }} />
              <Typography variant="h6" style={{ marginTop: '10px', color: 'gray' }}>Add New Robot</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default RobotOverview;