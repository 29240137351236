import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ridescanLogo from '../assets/ridescanLogo.png';  // Go up one level from 'components' to 'src', then into 'assets'

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);  // State to manage account menu
  const navigate = useNavigate();

  // Open the account menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the account menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle logout
  const handleLogout = () => {
    // Perform logout logic (e.g., remove token, etc.)
    localStorage.removeItem('token');  // Assuming you're using localStorage for token management
    navigate('/login');  // Redirect to the login page
  };

  return (
    <AppBar position="static" style={{ background: '#2254c5' }}>
      <Toolbar>
        {/* RideScan Logo */}
        <IconButton edge="start" color="inherit" onClick={() => navigate('/dashboard')} style={{ marginRight: '20px' }}>
          <img src={ridescanLogo} alt="RideScan Logo" style={{ height: '40px' }} />
        </IconButton>

        {/* Title or Placeholder (Optional) */}
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          
        </Typography>

        {/* Account Icon with dropdown menu */}
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <AccountCircleIcon />
        </IconButton>

        {/* Account dropdown menu */}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
